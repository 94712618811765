import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logoImg from '../female_cofounders_logo_white.svg'
import meetupImg from '../social_media/meetup_icon.svg'
import linkedInImg from '../social_media/linkedin_icon.svg'
import facebookImg from '../social_media/facebook_icon.svg'
import slackIcon from '../social_media/slack.png'
import instagramIcon from '../social_media/instagram.svg'

const social = {
  meetup: {
    title: "Meetup",
    img: meetupImg,
    url: "https://www.meetup.com/de-DE/Female-Co-Founders/"
  },
  linkedIn: {
    title: "LinkedIn",
    img: linkedInImg,
    url: "https://www.linkedin.com/groups/13566620/"
  },
  facebook: {
    title: "Facebook",
    img: facebookImg,
    url: "https://www.facebook.com/groups/femalecofounders/"
  },
  slack: {
    title: "Slack",
    img: slackIcon,
    url: "https://join.slack.com/t/femalecofounders/shared_invite/zt-e5y85n6b-6ufe6~WnstPbmIfIIpoflg"
  },
  instagram: {
    title: "Instagram",
    img: instagramIcon,
    url: "https://www.instagram.com/female.cofounders/"
  }
}

const Section1 = ({}) => (
  <section className="container py-10 md:py-26 lg:py-24 xl2:py-40">
    <div className="text-sm text-gradient uppercase font-display font-bold">Co-Founder-Suche</div>
    <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold lg:w-6/12">Wie wir unterstützen</h2>
    <hr className="border-meteor-500 border-2 w-1/5 my-6" />
    <div className="lg:flex mt-12">
      <div className="mb-8 lg:mb-0 lg:w-4/12 lg:mx-8 text-center border-mulberry-500 border rounded-lg py-12 px-10 lg:flex flex-col justify-between">
        <h3 className="text-gradient font-bold text-2xl mb-8">Matchmaking</h3>
        <p className="font-light leading-relaxed mb-8">
          Trage dich als potentieller Female Co-Founder (w) ohne eigene Gründungsidee <strong className="font-bold">oder</strong> als Founder (m/w/d)
          mit eigener Idee oder bereits gegründeten Unternehmen in unsere Datenbank ein.
          <br /><br />
          Sobald es ein passendes Match gibt, melden wir uns!
        </p>
        <div>
          <a href ="https://docs.google.com/forms/d/e/1FAIpQLSc_RBSJsFrBM2aBqad2pXNtl0EZ8jcjcTDjtMEUS6sffcB4tA/viewform" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Female Co-Founder
          </a>
          <div className="mb-4">(ohne Idee)</div>
          <br />
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScjdIp2DyxFWGO7dsgEB5qWCf0W7v3ydjaEVHOwmL446sxAFQ/viewform" target="_blank" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Founder
          </a>
          <div className="mb-4">(mit Idee)</div>
        </div>
      </div>
      <div className="mb-8 lg:mb-0 lg:w-4/12 lg:mx-8 text-center border-mulberry-500 border rounded-lg py-12 px-10 lg:flex flex-col justify-between">
        <h3 className="text-gradient font-bold text-2xl mb-8">Co-Founder-Gesuch über Social Media</h3>
        <p className="font-light leading-relaxed mb-8">
          Als Founder (m/w/d) oder potentielle Mitgründerin (w) hast du die Möglichkeit, ein Co-Founder-Gesuch auf den Social Meda Kanälen von Female Co-Founders (LinkedIn, Facebook, Instagram) 
          zu veröffentlichen. Diese Option kannst du in den Formularen, um dich in unsere Datenbank fürs Matching eintragen zu lassen, auswählen.
        </p>
        <div>
          <a href ="https://docs.google.com/forms/d/e/1FAIpQLSc_RBSJsFrBM2aBqad2pXNtl0EZ8jcjcTDjtMEUS6sffcB4tA/viewform" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Female Co-Founder
          </a>
          <div className="mb-4">(ohne Idee)</div>
          <br />
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScjdIp2DyxFWGO7dsgEB5qWCf0W7v3ydjaEVHOwmL446sxAFQ/viewform" target="_blank" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Founder
          </a>
          <div className="mb-4">(mit Idee)</div>
        </div>
      </div>
      <div className="mb-8 lg:mb-0 lg:w-4/12 lg:ml-8 text-center border-mulberry-500 border rounded-lg py-12 px-10 lg:flex flex-col justify-between">
        <h3 className="text-gradient font-bold text-2xl mb-8">Community</h3>
        <p className="font-light leading-relaxed mb-8 flex-grow">
          <b>Zielgruppe:<br /> Alle mit und ohne Startup-Idee</b><br /><br />
          Werde Teil unserer Community! Um dein Co-Founder-Gesuch zu posten oder die Post anderer zu lesen,
          tritt unserer Community auf Facebook, Meetup und LinkedIn bei! Möchtest du dein Gesuch auch
          über unsere offiziellen Kanäle streuen, dann melde dich per Mail bei uns!
        </p>
          <div className="mt-8 flex justify-center flex-wrap">
            {Object.values(social).map(data => (
              <a key={data.title} href={data.url} target="_blank" className={`bg-meteor-500 mb-1 bg-gradient-l-mulberry-500 hover:opacity-50 rounded-full mx-2 w-12 h-12 flex justify-center p-3`}>
                <img src={data.img} alt={data.title} />
              </a>
            ))}
          </div>
        </div>
    </div>
  </section>
)

Section1.propTypes = {
}

Section1.defaultProps = {
}

export default Section1
