import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/co_founder_bottom_mobile.png'
import photo from '../photos/co_founder_bottom.png'

const Section2 = ({}) => (
  <div className="relative mb-16 lg:mt-20">
    <div className="md:rotated-bg absolute bg-pampas">
    </div>
    <div className="bg-pampas h-16 absolute w-full" style={ { bottom: "-4rem", zIndex: -1 } }></div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper cf2 hidden md:block" style={ { bottom: "-4rem", zIndex: -1 } }>
      <div className="fc-header cf2" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="bg-pampas md:bg-transparent">
      <div className="container pt-8 pb-4 md:pt-12 md:pb-0 lg:pt-12  xl:pt-20 xl:pb-4 xl2:pt-42 xl2:pb-8 md:flex">
        <div className="md:w-6/12 lg:w-5/12"></div>
        <div className="md:w-6/12 lg:w-7/12">
          <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-1 md:pt-6 text-gradient">Building your network</div>
          <h1 className="text-3xl lg:text-5xl leading-none font-display font-bold">Events</h1>
          <hr className="border-meteor-500 border-2 w-1/5 my-6" />
          <p className="font-light leading-relaxed mb-10">
            Wenn du dich vernetzen und von anderen Ideen inspirieren lassen möchtest, melde dich bei einem unserer (regionalen)
            Online-Networking-Events an. Du kannst außerdem deine Startup-Idee auf einer Female Co-Founders Pitch Night präsentieren.
            Checke unsere kommenden Events und bewirb dich für eine Pitch Night in deiner Nähe.
          </p>
          <Link to="/events" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            Kalender
          </Link>
        </div>
      </div>
    </div>
  </div>
)

Section2.propTypes = {
}

Section2.defaultProps = {
}

export default Section2
