import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/co_founder_mobile_header.png'
import photo from '../photos/co_founder_header.png'

const Header = ({}) => (
  <div className="relative mb-16 bg-pampas" style={{ zIndex: -5 }}>
    <div className="rotated-bg absolute bg-pampas">
    </div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper cf hidden md:block">
      <div className="fc-header cf" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="container py-8 md:py-12 xl:py-24 xl2:py-42">
      <div className="md:w-8/12 lg:w-7/12">
        <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4">Co-Founder</div>
        <h1 className="text-4xl lg:text-6xl leading-none font-display font-bold text-gradient">Connecting entrepreneurs</h1>
        <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
        <p className="font-light leading-relaxed">
          Wir bringen euch zusammen: Passionierte Ideenentwickeler*innen, Umsetzer*innen, Zahlenheld*innen, Tech-Enthusiast*innen,
          Fachexpert*innen, Fachidot*innen, Männer, Frauen und alle dazwischen.
        </p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
