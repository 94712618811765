import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Header from "../components/coFounder/header"
import Section1 from "../components/coFounder/section1"
import Section2 from "../components/coFounder/section2"
import SEO from "../components/seo"

const CoFounderPage = () => (
  <Layout>
    <SEO title="Co-Founder" />
    <Navigation isAbsolute={false} currentPage="co-founder" inverted />
    <Header />
    <Section1 />
    <Section2 />
  </Layout>
)

export default CoFounderPage
